.ag-style {
  height: 500px;
  width: 100%;
}

.vertical-middle {
  display: flex;
  align-items: center;
}

.vertical-middle img {
  display: block;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.last-menu-item {
  margin-left: 150px;
}